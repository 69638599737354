/* eslint-disable import/prefer-default-export */
export const configColumns = [
  {
    key: 'email',
    label: 'Nama & Email',
    sortable: true,
    class: 'p-1 text-#626262',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '12px',
      color: '#626262',
    },
  },
  {
    key: 'city',
    label: 'Kota/Kabupaten',
    class: 'p-1 text-#626262',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '12px',
      color: '#626262',
    },
  },
  {
    key: 'phone_no',
    label: 'Whatsapp',
    class: 'p-1 text-#626262',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '12px',
      color: '#626262',
    },
  },
  {
    key: 'social_media',
    label: 'Sosial Media',
    class: 'p-1 text-#626262',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '12px',
      color: '#626262',
    },
  },
  {
    key: 'aksi',
    label: 'Aksi',
    class: 'p-1 text-#626262',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '12px',
      color: '#626262',
    },
  },
]
export const configColumnsDetail = [
  {
    key: 'no',
    label: 'No',
    class: 'p-1 text-#626262',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '12px',
      color: '#626262',
    },
  },
  {
    key: 'title',
    label: 'Nama Acara',
    class: 'p-1 text-#626262',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '12px',
      color: '#626262',
    },
  },
  {
    key: 'position',
    label: 'Posisi',
    class: 'p-1 text-#626262',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '12px',
      color: '#626262',
    },
  },
  {
    key: 'event_date',
    label: 'Waktu',
    class: 'p-1 text-#626262',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '12px',
      color: '#626262',
    },
  },
  {
    key: 'location',
    label: 'Lokasi',
    class: 'p-1 text-#626262',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '12px',
      color: '#626262',
    },
  },
  {
    key: 'max_attendance',
    label: 'Jumlah Peserta',
    class: 'p-1 text-#626262',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '12px',
      color: '#626262',
    },
  },
]
